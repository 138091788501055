/* stylelint-disable rule-empty-line-before */
.rcorners2, .rcorners3, .rcorners4 {
  border-radius: 10px;
  border-width: 2px;
  padding: 30px;
  width: 15rem;
  height: 6rem;
  margin-left: 2rem;
  transition: background-color 0.3s ease;
  font-size: 0.8rem;
  margin-right: 5px; /* Ajusta el margen derecho según tus necesidades */
  text-align: center;
  display: inline-block;
background-color: #f0f0f0 !important;
border-color: #F3F3F3;
}

.button-exit {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 20px;
  border: 2px solid #333;
  border-radius: 50px;
  background-color: white;
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 150px;
}

.button-exit:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button-exit img {
  width: 20px;
  height: 20px;
}


.popup-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  background: white;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.popup-header {
  width: 100%;
  max-width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.popup-icon {
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-image-wrapper {
  width: 68.05px;
  height: 58.65px;
  position: relative;
}

.popup-title {
  text-align: center;
  color: #374151;
  font-size: 24px;
  font-family: Lato, sans-serif;
  font-weight: 600;
  line-height: 39px;
  word-wrap: break-word;
}

.popup-subtitle {
  text-align: center;
  color: #637381;
  font-size: 18px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.popup-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  flex-wrap: wrap;
}

.popup-button-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: 400px;
}

.popup-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 24px;
  border-radius: 50px;
  box-shadow: 0px 10px 15px rgba(5, 13, 29, 0.18);
  cursor: pointer;
}

.back-button {
  border: 2px solid #1f2a37;
  color: #1f2a37;
  font-size: 16px;
  font-family: Lato, sans-serif;
  font-weight: 700;
}

.back-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(105%);
}

.confirm-button {
  background: #2cd673;
  border: 2px solid white;
  color: white;
  font-size: 16px;
  font-family: Lato, sans-serif;
  font-weight: 700;
}

.confirm-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(105%);
}

.popup-button-icon {
  width: 20px;
  height: 20px;
  position: relative;
}

.confirm-button .popup-button-icon {
  width: 24px;
  height: 24px;
}

@media(max-width:460px){
  .popup-button-group{
    display: contents;
  }
}


.container-aplication{
  display: flex;
  flex-direction: column;
  margin-bottom: 20rem;
}

.containerteer{
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
}

.container-familiar{
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
}


.radio-selector {
 display: flex !important;
  width: 314px;
  height: 56px;
  padding: 16px 15px;
  border-radius: 10px;
  border: 1px solid #1F2A37; /* Cambié el color del borde a un tono de gris azulado, puedes ajustarlo según tu preferencia */
  align-items: center;
  margin-top: 10px;
  margin-bottom:10px;
  
}
.radio-selector-expired{
  display: flex !important;
  width: 374px;
  height: 56px;
  padding: 16px 30px;
  border-radius: 10px;
  border: 1px solid #1F2A37; /* Cambié el color del borde a un tono de gris azulado, puedes ajustarlo según tu preferencia */
  align-items: center;
  gap: 10px !important;
}

.checkbox{
  margin-right: 10px;
}

.checkbox-input {
  /* Estilo predeterminado del checkbox */
  background-color: #F9FAFB; /* Color de fondo del checkbox */
  border: 1px solid #111928; /* Borde del checkbox */
  border-radius: 4px; /* Borde redondeado */
  width: 20px; /* Ancho del checkbox */
  height: 20px; /* Altura del checkbox */
  margin-right: 10px; /* Margen a la derecha del checkbox */
  cursor: pointer; /* Cambiar el cursor al pasar sobre el checkbox */
}

.checkbox-input:checked {
  background-color: #13C296; /* Cambiar el color de fondo cuando el checkbox está marcado */
}


.radio-selector:hover {
  display: flex !important;
   width: 314px;
   height: 56px;
   padding: 16px 30px;
   border-radius: 10px;
   border: solid 1px #57DE8F; /* Cambié el color del borde a un tono de gris azulado, puedes ajustarlo según tu preferencia */
   align-items: center;

 }

.radio-selector p {
  font-family: Lato, sans-serif;
  color:black !important;
}

.radio-selector input[type="radio"] {
  margin-right: 10px; /* Add space between the radio button circle and text */
  border: 2px solid red; /* Set the border of the radio button circle to red */
  border-radius: 50%; /* Make the radio button circle circular */

}

.circle-exclamation {
  display: inline-block;
}

.circle-exclamation::after {
  content: "!";
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: #0B76B7;
  color: white;
  border-radius: 50%;
  font-size: 1.2rem;
  vertical-align: middle;
  margin-left: 10px;
}

.button-inside{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px
}

.button-text {
  width: 100%;
  margin-top: 1rem;
  color:#bf3c3c;
  font-weight:bold;
  font-size: 0.8rem;
  text-align: center;
  transition: 0.2s;
}

.button-text:hover {
  transition: 0.2s;
  font-size: 0.9rem;
}

.rcorners2 {
  border: 1px solid transparent; /* Borde predeterminado transparente */
  transition: border-color 0.3s ease; /* Para animar el cambio de borde */
  cursor: pointer; /* Para mostrar el cursor de puntero */
}

.rcorners2:hover {
  border: 1px solid #57DE8F; /* Color del borde cuando se pasa el mouse */
}

.rcorners2.selected {
  border: 1px solid #57DE8F; /* Estilo cuando está seleccionado */
}

.rcorners2 {
  background-color: #FFF;
}

.rcorners2:hover {
  background-color: #F9FAFB;
border: solid 1px #57DE8F;
}

.rcorners3 {
  border-color: #3b00b1;
  background-color: #FFF;
}

.rcorners3:hover {
  background-color: #3b00b1;
  color : white
}

.rcorners4 {
  border-color: #3b00b1;
  background-color: #FFF;
  margin-top:0 ;
}

.rcorners4:hover {
  background-color: #3b00b1;
  color: white
}

.bottoninfo {
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top : 5px;
   max-width: 17.5rem;
  padding-left: 45px;
  align-items: center;
}

.maincontainer {
  display: flex;
  flex-flow: row wrap;
  place-content: center center;
  align-items: center;
}

.buttonscontainer {
  display: flex;
  flex-flow: row wrap;
  place-content: center center;
  align-items: center;
}

.br {
  font-size: 1rem;
  padding-top: 3rem;
}

.br1 {
 color : white ;
 font-size: 1rem;
  padding-top: 3rem;
}

.title {
  font-size: 2rem;
  padding-top: 3rem;
}

.containerbackandnextbuttons {
  font-family: Lato, sans-serif !important;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1rem;
}

.containerbuttonselect {
  font-family: Lato, sans-serif !important;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.buttonoption {
  border-radius: 45px;
  border-width: 2px;
  padding: 20px;
  width: 15rem;
  height: 6rem;
  margin-left: 2rem;
  transition: background-color 0.3s ease;
  font-size: 1.3rem;
  margin-right: 5px; /* Ajusta el margen derecho según tus necesidades */
  text-align: center;
  display: inline-block;
  border-color: #3b00b1;
  background-color: #FFF;
}


.buttonoption:hover {
  background-color: #3b00b1;
  color: white
}

.bottoninfoclosework {
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top : 5px;
 max-width: 17.5rem;
 padding-left: 45px;
 align-items: center;
}

.backbutton {
  font-family: Lato, sans-serif !important;
      font-size: 1.1rem;
      color: #333 !important;
      background-color: white;
      border: 1px solid #333;
      padding: 20px;
      width: 14rem;
      height: 4rem;
      border-radius: 45px;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
}

.nextbutton {
  font-family: Lato, sans-serif !important;
  font-size: 1.1rem;
  color: white !important;
  background-color: #b10000;
  border-color: #b10000;
  padding: 20px;
  width: 8rem;
  height: 3rem;
  border-radius: 45px;
  transition: all 0.2s ease-in-out;
}

.containerbackandnextbuttonsmain {
  font-family: Lato, sans-serif !important;
  display: flex;
  justify-content: space-evenly;
}

.containerbackandnextbuttonsdisc {
  font-family: Lato, sans-serif !important;
  display: flex;
  justify-content: space-evenly;
}


.buttonx {
  font-size: 2rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  border: none;
  margin-left: 20px;

}

.buttonx:hover {
  background-color: #bf3c3c;
  border-color: #bf3c3c;
  color: #fff;
  padding : 5px;
  border-radius: 50%;
}

.backbutton:hover, .backbutton:focus, .backbutton:active,
.nextbutton:hover, .nextbutton:focus, .nextbutton:active {
  animation: pulse 1s ease-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.backbutton:hover, .backbutton:focus, .backbutton:active {
  color: #3b00b1 !important;
  background-color: #fff;
  border : 2px solid #3b00b1;
}

.nextbutton:hover, .nextbutton:focus, .nextbutton:active {
  color: #b10000 !important;
  background-color: #fff;
  border : 2px solid #b10000;
}

.checkboxes-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.titledivquestions {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: Lato, sans-serif !important;
  font-size: 1.4rem;
  padding: 3rem 30px 1rem;
  margin-bottom: 2rem;
  width: 100%;
}

.titledivquestions-expired {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: Lato, sans-serif !important;
  font-size: 1.4rem;
  padding: 3rem 30px 1rem;
  margin-bottom: 2rem;
  width: 100%;
}

/* Andres */
h1{
font-family: Lato, sans-serif !important;
font-size: 1.2rem;
}

.formstyling {
  display: flex;
  flex-flow: column wrap;
  place-content: center center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.scenario {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.scenario-text {
  width: 17rem;
  height: 8rem;
  margin-bottom: 2rem;
}

.modalborder {
  box-shadow: 2px 10px 30px 1px#b10000a9;
  border-radius: 30px;
  height: 20rem;
}

.p-fileupload .p-fileupload-buttonbar{
  background-color: white;
  border:none;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.p-fileupload .p-fileupload-content{
  background-color: white;
  border:none;
  padding: 0 1rem;
}

.popupwindows {
  display: flex !important;
  justify-content: space-between !important;
  padding-left: 5px !important;
  padding-right: 5px !important;

 }

.anticon svg {
  padding-bottom: 3px;

}

.popup {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 9999 !important;
  backdrop-filter: blur(1px) !important;;
  background-color: rgb(255 255 255 / 55.5%) !important;
  animation: open-box-modal 0.5s ease forwards;
}

.checkcontract {
  background-color: rgb(255 242 243);
  color : black;
  padding: 0.5rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  text-decoration: none;
  margin-bottom: 0.75rem;
  outline: none;
  width: 100%;
  line-height: 1rem;
  border-radius: 0.25rem;
  font-family: Lato, sans-serif !important;
  font-weight: bold;
}

.Infocircle {
  color: #b10000;
  font-size: 20px !important;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

.info-circle-form {
  color: #0B76B7;
  font-size: 20px !important;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

.titleverify {
  font-family: Lato, sans-serif !important;
  font-size: 0.8rem;
  padding-top: 0rem;
  font-weight: bold;
}

.button-container {
  display: flex;
  align-items: space-between;
  padding-right: 3rem;
}

 .ant-spin-dot-item {
  background-color: #b10000;
}

.buttonverify {
  font-family: Lato, sans-serif !important;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #18BFFF;
  padding: 0.3rem .7rem;
  border-radius: 30px;
  margin-left: .5rem;
  margin-right: 0rem;
}



.buttonverify:hover {
  border: 1px solid #18BFFF;
  background-color: #D0F0FD;
}

.checkcontract:hover {
  font-family: Lato, sans-serif !important;
  font-size: 0.8rem;
  font-weight: normal;
  color: #1d1d1d;
}

.titleverify span {
padding-bottom: 5px;
}

.form {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: Lato, sans-serif !important;
  font-weight: bold;
}

.switch input {
  position: absolute;
  opacity: 0;
}

/**
 * 1. Adjust this to size
 */

.switch {
  display: inline-block;
  font-size: 20px; /* 1 */
  height: 1em;
  width: 2em;
  background: #BDB9A6;
  border-radius: 1em;
}

.switch div {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: red;
  box-shadow: 0 0.1em 0.3em rgb(0 0 0 / 30%);
  transition: all 300ms;
}

.switch input:checked + div {
  transform: translate3d(100%, 0, 0);
}


.custom-input {
  width: 76%;
  height: 46px;
  border: 1px solid #DFE4EA;
  border-radius: 6px;
  padding: 12px 6px 12px 6px;
  margin-bottom: 10px; /* Esto es el espaciado entre campos */
  font-size: 12px; /* Ajusta el tamaño de fuente según tus preferencias */
  color: #000; /* Cambia el color de texto según tus preferencias */
  background-color: #fff !important;
}

.custom-input:hover {
  width: 76%;
  height: 46px;
  border: 1px solid #57DE8F;
  border-radius: 6px;
  padding: 12px 6px 12px 6px;
  margin-bottom: 10px; /* Esto es el espaciado entre campos */
  font-size: 12px; /* Ajusta el tamaño de fuente según tus preferencias */
  color: #000; /* Cambia el color de texto según tus preferencias */
  background-color: #fff !important;
}

.text-tomato-500.text-xs.italic {
  display: block;        /* Ensure it's a block element */
  margin-top: 7px !important;
  max-width: 100%;      /* Adjust width to force line break at desired point */
  word-break: break-word; /* Allows breaking words if needed to fit within width */
}


.custom-input:focus {
  width: 76%;
  height: 46px;
  border: 1px solid #57DE8F;
  border-radius: 6px;
  padding: 12px 6px 12px 6px;
  margin-bottom: 10px; /* Esto es el espaciado entre campos */
  font-size: 12px; /* Ajusta el tamaño de fuente según tus preferencias */
  color: #000; /* Cambia el color de texto según tus preferencias */
  background-color: #fff !important;
}





.style-label{
  margin-bottom: 17px !important;
}

.form-tab-continer{
  border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 1rem;
}

.custom-input-button{
  width: 98%;
  height: 46px;
  border-bottom: 1px solid #DFE4EA;
  padding: 12px 16px 12px 20px;
  margin-bottom: 10px; /* Esto es el espaciado entre campos */
  font-size: 12px; /* Ajusta el tamaño de fuente según tus preferencias */
  color: #000; /* Cambia el color de texto según tus preferencias */
  background-color: transparent; /* Fondo transparente por defecto */
  transition: background-color 0.2s; /* Transición suave para el cambio de color */
  display: flex;
  align-items: flex-start;
}

.custom-input-button:active{
  width: 98%;
  height: 46px;
  border: 1px solid #DFE4EA;
  border-radius: 6px;
  padding: 12px 16px 12px 20px;
  margin-bottom: 10px; /* Esto es el espaciado entre campos */
  font-size: 12px; /* Ajusta el tamaño de fuente según tus preferencias */
  color: #000; /* Cambia el color de texto según tus preferencias */
  background-color: rgb(191 167 228) !important;
  display: flex;
  align-items: flex-start;
}

.custom-input-button:focus{
  width: 98%;
  height: 46px;
  border: 1px solid #DFE4EA;
  border-radius: 6px;
  padding: 12px 16px 12px 20px;
  margin-bottom: 10px; /* Esto es el espaciado entre campos */
  font-size: 12px; /* Ajusta el tamaño de fuente según tus preferencias */
  color: #000; /* Cambia el color de texto según tus preferencias */
  background-color: rgb(191 167 228) !important;
  display: flex;
  align-items: flex-start;
}

button.custom-input-button:active {
  /* Estilos cuando se hace clic en el botón */
  background-color: rgb(191 167 228) !important;
}

.custom-input-error{
  width: 100%;
  height: 60px;
  border: 1px solid #DFE4EA;
  padding: 12px 16px 12px 20px;
  margin-bottom: 10px; /* Esto es el espaciado entre campos */
  font-size: 12px; /* Ajusta el tamaño de fuente según tus preferencias */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-input-success{
  width: 100%;
  height: 46px;
  border: 1px solid #DFE4EA;
  padding: 12px 16px 12px 20px;
  margin-bottom: 10px; /* Esto es el espaciado entre campos */
  font-size: 12px; /* Ajusta el tamaño de fuente según tus preferencias */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.success-flag-container{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background-color: #DAF8E6;
  color: #004434;
  padding: 0.3rem 0.5rem;
  border-radius: 10px;
  align-items: center;
  font-size: 12px;
}

.success-flag-simbol div{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid white;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
}

.success-flag-simbol {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #22AD5C ;
  display: flex;
  align-items: center;
  justify-content: center;
}




.error-flag-container{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background-color: #FEF3F3 !important;
  color: #BF3C3C;
  padding: 0.3rem 0.5rem;
  border-radius: 10px;
  align-items: center;
  font-size: 12px;
}

.error-flag-simbol div{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid white;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
}

.error-flag-simbol {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #BF3C3C ;
  display: flex;
  align-items: center;
  justify-content: center;
}




.button-container-member{
  display: flex;
  flex-direction: row;
  place-content: center center;
}


.button-disabled {
  background-color: #080808 !important; /* Establece el color de fondo gris deseado */

  /* Otros estilos para el botón deshabilitado */
}

.circle-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap:5px;
  margin-left: 1rem;
  justify-content: center;
  flex-flow: row wrap;
  font-family: Lato, sans-serif;
}

.text-circle{
  margin-bottom: 1px;
}
/*
.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #374151;
  color: white;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  margin-right: 10px;
  font-family: Lato, sans-serif;
}

.circle.black-bg {
  font-family: Lato, sans-serif;
  background-color: white;
  color: #374151;
  border-radius: 50%;
  border: 2px solid #374151;
  display: flex;
  align-items: center;
  justify-content: center;
}
  */
/*
.circle-container {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    flex-flow: row wrap;
    font-family: Lato, sans-serif;
}
*/

.stepper-container {
  display: 'flex';
  justify-content: 'space-between';
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.progress-line {
  position: relative;
  width: 100%;
  top: px; /* Lowered position of the gray line */
  right: 0;
  margin-bottom: 20px;
  height: 2px;
  background-color: #DFE4EA; /* Light gray background for the entire line */
  z-index: 1; /* Place it behind the steps */
}

.progress-line-completed {
  position: absolute;
  height: 2px;
  background-color: #13C296; /* Green for completed part */
  width: 33%; /* Set this based on completed steps (33% for 1 step completed) */
  left: 0;
}

.progress-line-completed2 {
  position: absolute;
  height: 2px;
  background-color: #13C296; /* Green for completed part */
  width: 33%; /* Set this based on completed steps (33% for 1 step completed) */
  left: 33%;
}

.progress-line-completed3 {
  position: absolute;
  height: 2px;
  background-color: #13C296; /* Green for completed part */
  width: 33%; /* Set this based on completed steps (33% for 1 step completed) */
  left: 66%;
}

.step {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 15px;
  z-index: 1; /* Keep above the line */
}

.step-label {
  margin-left: 15px;
  font-size: 13px;
  color: #1F2A37;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #374151;
  background-color: white; /* Dark background for inactive steps */
  color: #374151;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-weight: 700;
}

.step.completed .circle {
  background-color: white; /* Green for completed step */
  color: #374151;
}

.step.selected .circle {
  background-color: #374151; 
  color: white; /* White color for selected step */
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #DFE4EA; /* Light gray color for inactive dots */
  position: absolute;
  top: -4px; /* Align with the gray line */
}

.dot-uncompleted1 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #DFE4EA; /* Light gray color for inactive dots */
  position: absolute;
  top: -4px; /* Align with the gray line */
  left:66%;
}

.dot-uncompleted2 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #DFE4EA; /* Light gray color for inactive dots */
  position: absolute;
  top: -4px; /* Align with the gray line */
  left:100%
}

.completed-dot {
  background-color: #13C296; /* Green color for completed dot */
  z-index: 1;
  left: 0%;
}

.completed-dot1 {
  left: 100%;
  background-color: #13C296; /* Green color for completed dot */
}

.checkmark-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #FFFFFF; /* Solid white background */
  position: absolute;
  top: -8px;
  left: 96%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* Green border around the circle */
  border: 2px solid #13C296;
  box-sizing: border-box;
  z-index: 2; /* Ensure it is in front of other elements */
}

.checkmark {
  width: 5px; /* Adjust size of the checkmark */
  height: 9px;
  border: solid #13C296;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  background-color: transparent; /* Ensure the checkmark itself has no background */
  position: relative;
  z-index: 3; /* Ensure it stays above any other elements */
}

@media(min-width:990px){
  .button-exit{
    position: relative;
    left: 2%;
  }
  .stepper-container {
    gap: 20px;
  }

  .step{
    margin-left: 30px;
  }
  
  .step.completed .circle {
    background-color: white; /* Green for completed step */
    color: #374151;
  }
  
  .step.selected .circle {
    background-color: #374151; 
    color: white; /* White color for selected step */
  }
}

@media (max-width: 1215px) {
  .step-label{
      font-size: 12px;
    }
}


@media (max-width: 990px) {
  .step-label{
  /*.progress-line,
  .progress-line-completed,
  .dot */
    display: none;
  }
  .button-exit{
    left: 1%;
  }

  /* Optionally reduce gap on mobile for tighter layout */
  .stepper-container {
    left:20px;
    gap: 80px;
  }
}

@media (max-width: 990px) {
  .button-exit{
    position: relative;
    left: 1%;
  }
  .step-two-container{
    display: inline-flex;
  }

}

@media (max-width: 750px) {
  .stepper-container {
    left:5px;
    gap: 20px;
  }
  .button-exit {
    width: 50px; /* Make button a square */
    height: 50px;
    border-radius: 50%; /* Fully round the corners */
    padding: 0; /* Remove padding to center icon */
    font-size: 0; /* Hide the text */
    gap: 0; /* Remove spacing between content */
    justify-content: center; /* Center the icon */
    left: 6%;
  }

}

.step-two-container{
  margin-left: 20%;
  width: 60%;
  display: flex;
}

.step-two-container-cont{
  width: 100%;
  height: 80px;
  z-index: 10;
  top:-25px;
  margin-top: 30px;
  padding-right: 40px;
  padding-bottom: 5px;
  background: white;
  position: fixed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}



.circle-container2 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap:5px;
  margin-left: 1rem;
  justify-content: center;
  flex-flow: row wrap;
  font-family: Lato, sans-serif;
  width: auto;
}

.text-circle2{
  margin-bottom: 1px;
}

.circle2 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
  color: white;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  margin-right: 10px;
  font-family: Lato, sans-serif;
}

.circle.black-bg2 {
  font-family: Lato, sans-serif;
  background-color: white;
  color: rgb(0, 0, 0);
  border-radius: 50%;
  border: 2px solid rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}


.container-line{
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 40px;
}

.bubble{
  height: 10px;
  width: 10px;
  background-color: #13C296;
  border-radius: 50%;
}

.bubble2{
  height: 10px;
  width: 10px;
  background-color: gray;
  border-radius: 50%;
}

.bar{
  border: 1px solid #13C296;
  width: 15rem;
  height: 1px;
  position: relative;
  top: -5px;

}

.bar2{
  border: 1px solid gray;
  width: 15rem;
  height: 1px;
  position: relative;
  top: -5px;

}

.container-radio-select{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center;
}

.custom-switch {
  background: rgb(255 255 255);
  border: 1px solid rgb(91 33 182);
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.no-spinner {
  appearance: textfield;

  /* Additional styles as needed */
}

.custom-switch .ant-switch-handle::before {
margin-bottom: 2px;
  background-color: rgb(171 136 226) !important; /* Cambia el color del thumb aquí */
}

.custom-switch .ant-switch-handle::after {
  background-color: rgb(0 0 0) !important; /* Cambia el color del thumb aquí */
}

.ant-switch-checked:focus{
  background-color: rgb(91 33 182) !important;
}


.errors-main-flag {
  background-color: #FEF3F3 ;
  border-left: 5px solid #F23030 ;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  height: 5rem;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.container-wrapper-expired{
  display: flex !important;
flex-direction: column !important;
}

@media (width <= 640px) {
  .rcorners2, .rcorners3, .rcorners4 {
    margin-top: 2rem;
  }

  .buttonoption {
    margin-top: 2rem;
    margin-left: auto;
  }

  .containerbuttonselect {
    font-family: Lato, sans-serif !important;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
  }

  .buttonoption {
    border-radius: 45px;
    border-width: 2px;
    padding: 20px;
    width: 15rem;
    height: 6rem;
    margin-left: 2rem;
    transition: background-color 0.3s ease;
    font-size: 1.3rem;
    margin-right: 5px; /* Ajusta el margen derecho según tus necesidades */
    text-align: center;
    display: inline-block;
  }

  .bottoninfoclosework {
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top : 5px;
   max-width: 17.5rem;
   padding-left: 45px;
   align-items: center;
  }
}



@media (width <= 772px) {

  .radio-selector-expired{
    display: flex !important;
    width: 300px;
    height: 56px;
    padding: 16px 30px;
    border-radius: 10px;
    border: 1px solid #1F2A37; /* Cambié el color del borde a un tono de gris azulado, puedes ajustarlo según tu preferencia */
    align-items: center;
    gap: 10px !important;
  }

.card-1-expired{
  width: 100% !important;
  height: auto !important;
}

  .container-wrapper-expired{
    display: flex !important;
  flex-direction: column !important;
  }
  .circle-container {
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
  font-family: Lato, sans-serif;
  }

  .titledivquestions-expired {
    display: flex;
    justify-content: center !important;
    text-align: center;
    font-family: Lato, sans-serif !important;
    font-size: 1.4rem;
    padding: 3rem 30px 1rem;
    margin-bottom: 0rem !important;
    width: 100%;
  }
  .button-container-disc2{
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    gap:10px !important;
    align-items: center;
  }

  .backbutton-disc2{
    width: 12rem !important;
  }
.responsive-title-expired{
  font-size: 24px !important;
    font-weight: 600;
    font-family: 'Lato';
    color: #000000;
    display: block;
    white-space: normal;
    text-align: center;
    line-height: 2rem;
    word-wrap: break-word;
    width: 20rem !important;
}
  .containerbackandnextbuttonsdisc {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-evenly;
    background-color: white;
    padding: 10px;
    box-shadow: 0 -2px 5px rgb(0 0 0 / 20%);
    margin-bottom: -37px !important;
  }

  .backbutton, .nextbutton {
    width: 40%;
    margin: 0;
  }

  .containerbackandnextbuttonsmain {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-evenly;
    background-color: white;
    padding: 10px;
    box-shadow: 0 -2px 5px rgb(0 0 0 / 20%);
    margin-bottom: 0 !important;
  }

  .backbutton, .nextbutton {
    width: 40%;
    margin: 0;
  }

  .container-wrapper{
    display: flex !important;
  flex-direction: column !important;
  }
  .text_expired_resposive{
    font-size: 12px !important;
  }
}

.swal2-icon.swal2-success .swal2-success-ring {
  border: 4px solid #13C296 !important;
}

.swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: #13C296 !important;


}



.custom-input-datepicker-expired {
  width: 115px;
  height: 46px;
  border: 1px solid black;
  border-radius: 6px;
  padding: 12px 16px 12px 20px;
  margin-bottom: 10px; 
  font-size: 14px;
  color: black; 
  background-color: #fff !important;
}

.custom-input-datepicker-expired:hover {
  width: 115px;
  height: 46px;
  border: 1px solid #57DE8F;
  border-radius: 6px;
  padding: 12px 16px 12px 20px;
  margin-bottom: 10px;
  font-size: 14px; 
  color: black; 
  background-color: #fff !important;
}


.rejected-doc-yellow {
  background-color: #FFFBEB;
  width: 100%;
  height: 4.5rem;
  border-radius: 10px;
  border-left: 5px solid #FBBF24;
  display: flex;
  flex-direction: row;
  padding: 10px 2rem;
  align-items: center;
  gap: 1rem;
}


.rejected-doc-yellow-atip {
  background-color: #FFFBEB;
  width: 100%;
  height: 4.5rem;
  border-radius: 10px;
  border-left: 5px solid #FBBF24;
  display: flex;
  flex-direction: row;
  padding: 10px 2rem;
  align-items: center;
  gap: 1rem;
}



@media (width <= 1366px) {
  .rejected-doc-yellow-atip {
    background-color: #FFFBEB;
    width: 100% !important;
    height: 23.5rem !important;
    border-radius: 10px;
    border-left: 5px solid #FBBF24;
    display: flex;
    flex-direction: row;
    padding: 10px 2rem;
    align-items: center;
    gap: 1rem;
  }
}

