@import url('antd/dist/antd.min.css');
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Outfit:wght@300;400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Outfit';
  src: url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
  font-weight: 300, 400, 700;
}

body {
  font-family: 'Outfit', sans-serif !important;
  padding: 0;
}

body, html {
  margin: 0;
  padding: 0;
  min-height: 100%; /* Ensures the body takes up at least the full screen height */
  display: flex;
  flex-direction: column; /* Makes the body a flex container */
}


.footer-style{
  color: #FAFAFA !important;
  background-color: #000000 !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  display: flex;
  font-family: 'Outfit', sans-serif;
 }


 .footer-style-continer{
  width: 100%;
  max-width:100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 1rem;
  gap: 1rem;
  justify-content: center;
  background-color: #000000 !;
 }

 @media screen and (width <= 600px) {
  .footer-style{
    color: #FAFAFA !important;
    background-color: #000000!important;
    padding: 0 !important;
   }

   .footer-style-continer{
    padding: 2rem 0 !important;
    gap: 0 !important;
   }
}



.gif-container {
  background-image: url('./FormBuilder/logolaw.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  z-index: 100;
  width: 22%;
  height: 22%;
  animation-duration: 6s;
  animation-name: fadeout;
  animation-iteration-count: 1;

  /* Estilos para centrar el contenido */
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (width <= 768px) {
  .gif-container {
    background-image: url('./FormBuilder/video2.gif');
    background-size: contain;
    background-position: center;

    /* Estilos para mantener la proporción */
    max-width: 100%;
    max-height: 100%;
  }
}



@keyframes fadeout {
  0% {
    opacity: 1;
  }

98% {
    opacity: 1;
  }

  99% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

