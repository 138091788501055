

.back-button-form1 {
  background-color: white;
  color: #000 !important;
  border: 2px solid #333;
  box-shadow: 0 10px 15px 0 #050D1D2E;
  padding: 10px 24px 10px 24px;
  border-radius: 50px !important;
  gap: 10px;
align-items: center;
  display: flex;
  height: 40px;
}

.step-two-container {
  position: fixed;
  top: 30px;
}

.input_info{
  width: 50%;
}

.container-input-shopping{
  width: 50%;
}


.back-button-form1:hover {
  background: white;
  color: #333;
  border: 2px solid #333;
  box-shadow: 0 10px 15px 0 #050D1D2E;

  padding: 10px 24px 10px 24px;
  border-radius: 50px !important;
  gap: 10px;
  display: flex;
}


    .startbutton {
      font-family: Lato, sans-serif !important;
      font-size: 0.7rem;
      color: white !important;
      background-color: #333;
      border: 1px solid white;
      padding: 20px;
      width: 10rem;
      height: 2rem;
      border-radius: 45px;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
    }

    .select-style-shopping-CWP-LMIA{
      width: 150px !important;
    }

    .select-style-shopping,
    .new-select-style-sadsad,
    .select-style-shopping-LMIA,
    .select-style-shopping-CWP2,
    .select-style-shopping-CWP3,
    .select-style-shopping-CWP-LMIA
    {
      width: 270px;
      height: 46px;
      border-radius: 6px;
      border: 1px solid #DFE4EA;
      padding: 7px, 12px, 7px, 12px;
      place-content: center;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 1
   
    }


    .bckbutton {
      font-family: Lato, sans-serif !important;
      font-size: 1.1rem;
      color: white !important;
      background-color: #3b00b1;
      border-color: #3b00b1;
      padding: 20px;
      width: 14rem;
      height: 4rem;
      border-radius: 45px;
      transition: all 0.2s ease-in-out;
    }

    .startbutton-disc2{
      font-family: Lato, sans-serif !important;
      font-size: 1.1rem;
      color: white !important;
      background-color: #333;
      border: 1px solid white;
      padding: 20px;
      width: 14rem;
      height: 4rem;
      border-radius: 45px;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
    }

    .backbutton-disc2{
      font-family: Lato, sans-serif !important;
      font-size: 1.1rem;
      color: #333 !important;
      background-color: white;
      border: 1px solid #333;
      padding: 20px;
      width: 16rem;
      height: 4rem;
      border-radius: 45px;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
    }

    .startbutton-disc2:hover{
      font-family: Lato, sans-serif !important;
      font-size: 1.1rem;
      color: white !important;
      background-color: #333;
      border: 1px solid white;
      padding: 20px;
      width: 14rem;
      height: 4rem;
      border-radius: 45px;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
    }

    .backbutton-disc2:hover{
      font-family: Lato, sans-serif !important;
      font-size: 1.1rem;
      color: #3b00b1 !important;
      background-color: white;
      border: 1px solid #3b00b1;
      padding: 20px;
      width: 16rem;
      height: 4rem;
      border-radius: 45px;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
    }

    .button-container-disc2 {
      display: flex !important;
    }

    .startbutton-disc2FamGroup {
      font-family: Lato, sans-serif !important;
      font-size: 1rem;
      color: white !important;
      background-color: #333;
      border: 1px solid white;
      padding: 20px;
      width: 16rem;
      height: 4rem;
      border-radius: 45px;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
    }

    .startbutton-disc2FamGroup:hover {
      font-family: Lato, sans-serif !important;
      font-size: 1rem;
      color: #333 !important;
      background-color: white;
      border: 1px solid #333;
      padding: 20px;
      width: 16rem;
      height: 4rem;
      border-radius: 45px;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
    }

    .startbutton-disc2FamGroup-firmar{
        font-family: Lato, sans-serif !important;
            font-size: 1.1rem;
            color: white !important;
            background-color: white;
            border: 1px solid #333;
            padding: 20px;
            width: 14rem;
            height: 4rem;
            border-radius: 45px;
            transition: all 0.2s ease-in-out;
            text-transform: uppercase;

    }

    .startbutton-disc2FamGroup-firmar:hover, .startbutton-disc2FamGroup-firmar:focus, .startbutton-disc2FamGroup-firmar:active,
    .nextbutton:hover, .nextbutton:focus, .nextbutton:active {
      animation: pulse 1s ease-out;
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.1);
      }

      100% {
        transform: scale(1);
      }
    }

    .startbutton-disc2FamGroup-firmar:hover{
      font-family: Lato, sans-serif !important;
      font-size: 1rem;
      color: black !important;
      background-color: white;
      border: 1px solid #057533;
      padding: 20px;
      width: 16rem;
      height: 4rem;
      border-radius: 45px;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
    }

    .footer-style{
      color: #fff !important;
      background-color: #000000 !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 24px;
      display: flex;
      font-family: Lato, sans-serif;
     }

     .footer-style-continer{
      width: 100%;
      max-width:1200px;
      display: flex;
      flex-wrap: wrap;
      padding: 2rem 1rem;
      gap: 1rem;
      justify-content: center;
     }

     .custom-confirm-button {
      background-color: black; /* Establece el fondo del botón en negro */
      color: white; /* Establece el color del texto en blanco u otro color deseado */

      /* Otros estilos personalizados si es necesario */
    }

    @media screen and (width >= 900px) {
      /* CSS rules for screens up to 768px wide */
  .frame-info {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    flex: 1 auto;
  }

  .frame {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex:auto;
    text-align: left;
    flex-wrap: wrap;
    max-width: '110%'; 
    border-radius: '15px';
    margin-top: '25px'
  }


  .info {
    flex: 1;
  }

  .info-three {
    display: flex;
    color: white;
    width: 100%;
    align-items: center;
    padding: 4px;
    gap: 10px;
    margin-bottom: 0;
    justify-content: center;
  }

      .blue-div {
        width: 100%;
        display: flex;
        padding: 10px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }

      .blue-important {
        background-color: #C7B299;
        justify-content: center;
        border-radius: 10px;
        align-items: center;
        padding: 30px
      }

      .blue-services {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        text-align: left;
        padding-left:15px
      }

      .step-two-container {
        width: 60%;
        display: flex;
        position: fixed;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;
        text-align: left;
        border-bottom: '1px solid #DFE4EA'
      }

      .step-two-description{
         display: flex;
         flex-direction: column;
         justify-content: center;
         text-align: left;
         width: 60%;
      }

      .startbutton {
        font-family: Lato, sans-serif !important;
        font-size: 1.1rem;
        color: white !important;
        background-color: #333;
        border: 1px solid white;
        padding: 20px;
        width: 10rem;
        height: 4rem;
        border-radius: 45px;
        transition: all 0.2s ease-in-out;
      }


      .startbutton-first-page {
        font-family: Lato, sans-serif !important;
        font-size: 1rem;
        color: white !important;
        background-color: #333;
        border: 2px solid white;
        padding: 12px 24px;
        width: 10rem;
        height: auto;
        border-radius: 45px;
        transition: all 0.2s ease-in-out;
      }
    }



    @media screen and (width <= 990px) {
      .info-three {
        display: flex;
        color: white;
        width: 100%;
        align-items: center;
        padding: 4px;
        gap: 10px;
        margin-bottom: 0;
        justify-content: center;
      }

      .startbutton {
        font-family: Lato, sans-serif !important;
        font-size: 1.1rem;
        color: white !important;
        background-color: #333;
        border: 1px solid white;
        padding: 20px;
        width: 10rem;
        height: 4rem;
        border-radius: 45px;
        transition: all 0.2s ease-in-out;
      }

      .startbutton-first-page {
        font-family: Lato, sans-serif !important;
        font-size: 1rem;
        color: white !important;
        background-color: #333;
        border: 2px solid white;
        padding: 12px 24px;
        width: 10rem;
        height: auto;
        border-radius: 45px;
        transition: all 0.2s ease-in-out;
      }

      .startbutton-disc2{
        font-family: Lato, sans-serif !important;
        font-size: 1rem;
        color: white !important;
        background-color: #333;
        border: 1px solid white;
        padding: 20px;
        width: 12rem;
        height: 3rem;
        border-radius: 45px;
        transition: all 0.2s ease-in-out;
        text-transform: uppercase;
      }

      .backbutton-disc2{
        font-family: Lato, sans-serif !important;
        font-size: 1rem;
        color: #333 !important;
        background-color: white;
        border: 1px solid #333;
        padding: 20px;
        width: 6.5rem;
        height: 3rem;
        border-radius: 45px;
        transition: all 0.2s ease-in-out;
        text-transform: uppercase;
      }

      .startbutton-disc2:hover{
        font-family: Lato, sans-serif !important;
        font-size: 1rem;
        color: white !important;
        background-color: #333;
        border: 1px solid white;
        padding: 20px;
        width: 12rem;
        height: 3rem;
        border-radius: 45px;
        transition: all 0.2s ease-in-out;
        text-transform: uppercase;
      }

      .backbutton-disc2:hover{
        font-family: Lato, sans-serif !important;
        font-size: 1rem;
        color: #3b00b1 !important;
        background-color: white;
        border: 1px solid #3b00b1;
        padding: 20px;
        width: 6.5rem;
        height: 3rem;
        border-radius: 45px;
        transition: all 0.2s ease-in-out;
        text-transform: uppercase;
      }

      /* CSS rules for screens wider than 768px */
      .frame-info {
        width: 100%;
        margin-top: 5px;
        display: flex !important;
        flex-flow: row nowrap;
        justify-content: flex-start !important;
        flex: 1 auto !important;
        height: 56rem !important
      }

      .frame {
        width: 100%;


      }

      .info {
        flex: 1 !important;
      }

      .info-three {
        display: flex;
        color: white;
        width: 100%;
        align-items: center;
        padding: 4px;
        gap: 10px;
        margin-bottom: 20px
      }

      .blue-div {
        width: 100%;
        display: flex;
        padding: 15px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }

      .blue-important {
        background-color: #E1E8FF;
        justify-content: center;
        border-radius: 10px;
        align-items: center;
        padding: 20px;
      }

      .blue-services {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        text-align: left;
        padding-left:15px
      }

      .step-two-container {
        display: flex;
        flex-direction: column;
        width: 90%;
      }

      .step-two-description {
        width: 90%;
        margin-top: 1rem;
        margin-bottom: 2rem;
      }

      .startbutton {
        font-family: Lato, sans-serif !important;
        font-size: 0.8rem;
        color: white !important;
        background-color: #333;
        border: 1px solid white;
        padding: 20px;
        width: 10rem;
        height: 2rem;
        border-radius: 45px;
        transition: all 0.2s ease-in-out;
      }

    }
    .swal2-icon.swal2-success .swal2-success-ring {
      border: 4px solid #13C296 !important;
    }
  
    .swal2-icon.swal2-success [class^='swal2-success-line'] {
      background-color: #13C296 !important;
    }


    .swal2-icon.swal2-info.swal2-icon.swal2-info-ring{
      border: 4px solid #13C296 !important;
    }