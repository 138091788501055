/* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */
/* stylelint-disable declaration-block-no-redundant-longhand-properties */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.paralax-image {
  font-family: Montserrat, sans-serif;
  position: relative;
  height: 46vh !important;
  background-color: #6133AC ;
}

.paralax-content {
  font-family: Montserrat, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  width: 100%;
}

.paralax-header-icon-continer {
 width: 100%;
 position: absolute;
 display: flex;
 background-color: transparent;
 z-index: 9999;
 flex-direction: column;
 top: 240px;
}

.paralax-icon-header {
  z-index: -1;
  left: -100px;
  position: absolute;
  top: -200px;
  width: 500px;
}

.paralax-header-text-continer {
  font-family: Montserrat, sans-serif !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.paralax-background-text-blue {
  font-family: Montserrat, sans-serif !important;
  background-color: #5D5FEF;
  padding: 1rem 2rem;
  border-radius: 30px;
  width: fit-content;
}

.first-form-white-title-header {
  font-family: Montserrat, sans-serif !important;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
  color: #fff;
}

.paralax-content-info-blue {
  background-color: #6133AC;
  background-image: url('./assets/icon/estudio-studies2.svg');
  background-size:25%;
  background-position-x: 90%;
  background-position-y: 80%;
  background-repeat: no-repeat;
  padding: 20px 40px;
  margin-bottom: 0;
  display: block;
  position: relative;
  width: 100%;
}

.paralax-content-info-blue-work {
  background-color: #6133AC;
  background-image: url('./assets/icon/bg-trabajo.svg');
  background-size:25%;
  background-position-x: 90%;
  background-position-y: 80%;
  background-repeat: no-repeat;
  padding: 20px 40px;
  margin-bottom: 0;
  display: block;
  position: relative;
  width: 100%;
}

.paralax-content-info-blue-residence {
  background-color: #6133AC;
  background-image: url('./assets/icon/bg-residencia.svg');
  background-size:25%;
  background-position-x: 90%;
  background-position-y: 80%;
  background-repeat: no-repeat;
  padding: 20px 40px;
  margin-bottom: 0;
  display: block;
  position: relative;
  width: 100%;
}

.paralax-content-info-blue-tourism {
  background-color: #6133AC;
  background-image: url('./assets/icon/bg-turismo.svg');
  background-size:25%;
  background-position-x: 90%;
  background-position-y: 80%;
  background-repeat: no-repeat;
  padding: 20px 40px;
  margin-bottom: 0;
  display: block;
  position: relative;
  width: 100%;
}

.paralax-content-info-blue-refugio {
  background-color: #6133AC;
  background-image: url('./assets/icon/bg-refugio.svg');
  background-size:25%;
  background-position-x: 90%;
  background-position-y: 80%;
  background-repeat: no-repeat;
  padding: 20px 40px;
  margin-bottom: 0;
  display: block;
  position: relative;
  width: 100%;
}

.paralax-content-info-city {
  background-image: url('./assets/bg-canada-focus.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 100px 20px;
  margin-bottom: 0;
  display: block;
  width: 100%;
}

.paralax-content-info-yellow-gradient {
  width: 100%;
  height: fit-content;
  color: #fff;
  text-align: left !important;
  background: linear-gradient(180deg, #FFB800 0%, #FF7A00 100%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 100px 50px;
}

.paralax-content-info-yellow {
  font-family: Montserrat, sans-serif;
  background-color: #FFAE00;
  color: #fff;
  padding: 24px 24px 24px 100px;
  border-radius: 0 30px 30px 0;
  width: 50% !important;
  left: 0;
  z-index: 2;
  top: 0;
}

.paralax-content-info-blue-text-container {
  font-family: Montserrat, sans-serif;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.paralax-content-info-blue-text-container-inner {
  width: 100%;
}

.paralax-content-info-blue-text-container-inner-subtitle {
  font-family: Montserrat, sans-serif;
  font-size: 1.5rem;
  font-optical-sizing: auto;
  font-style: normal;
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color: white;
  opacity: 0.5;
}

.paralax-content-info-blue-text-container-inner-title {
  font-family: Montserrat, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  font-optical-sizing: auto;
  font-style: normal;
}

.paralax-content-info-blue-text-container-inner-text {
  font-family: Montserrat, sans-serif;
  padding-top: 1.2rem !important;
  color: #fff;
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

.paralax-video {
  width: 100%;
  height: 100vh;
}

.paralax-card-group-item {
  width: 33.33%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-start;
  gap: 36px;
}

.paralax-blue-text {
  color: #0050A6;
    width: 50%;
    font-size: 20px;
}

.paralax-testimonies-container {
  background-color: #fff;
  padding: 2rem;
  border-radius: 30px;
  width: 100%;
  max-width: 450px;
  min-width: 220px;
  height: 360px;
}

.paralax-globes {
  width: 1px;
  height: 10rem;
  border-left: 3px dashed white;
}

.paralax-white-cards-continer {
  gap: 36px;
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  padding-left: 0;
  margin-bottom: 0;
}

.paralax-white-cards-title {
  font-family: Montserrat, sans-serif;
  color: #8E4500;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 220%;
  border-bottom: 2px solid #8E4500;
}

.paralax-white-cards {
  font-family: Montserrat, sans-serif;
  width: 400px;
  display: flex;
  border-radius: 10px;
  background: white;
  box-shadow: 0 10px 10px 0 #DA6800;
  color: black;
  align-items: center;
  justify-content: center;
  gap: 36px;
}

.paralax-white-cards-inner {
  font-family: Montserrat, sans-serif;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
}

.paralax-white-cards-inner-title {
  font-family: Montserrat, sans-serif;
  color:  #374151;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.paralax-white-cards-inner-buybutton {
  font-family: Montserrat, sans-serif;
  display: flex;
  padding: 7px 21px;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;border-radius: 50px;
  border: 2px solid  #111928;
  background: #111928;
  box-shadow: 0 10px 15px 0 rgba(5 13 29 0.18);
}

.paralax-white-cards-inner-buybutton-text {
  font-family: Montserrat, sans-serif;
  color:  #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.paralax-white-cards-icons {
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 10px;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;
}

.paralax-final-button {
  font-family: Montserrat, sans-serif;
  width: fit-content;
  display: inline-flex;
  padding: 16px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 2px solid  #FFF;
  background:  #2CD673;
  box-shadow: 0 10px 15px 0 rgba(5 13 29 0.18);
  color:  #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex: 0 auto !important;
  text-transform: uppercase;
}

.paralax-menu-products-continer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 60px;
  align-self: stretch;
}

.paralax-menu-continer {
  display: flex;
  padding-top: 55px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.paralax-menu-btn {
  font-family: Montserrat, sans-serif;
  width: 12.5rem;
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border: 4px solid transparent;
}

.paralax-menu-btn:hover {
  border-color: #FFF;
  background: rgba(0, 0, 0, 0.50);
}

.paralax-menu-btn:focus {
  border-color: #FFF;
  background: rgba(0, 0, 0, 0.50);
}

.paralax-icons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.paralax-google-reviews-container-inner {
  margin-top: 6rem;
  margin-bottom: 6rem;
  display: flex;
  justify-content: space-between;
}

.paralax-google-reviews-testimonies-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-left: 5rem;
}

.paralax-image-products-menu {
  display: flex;
}

.paralax-image-products-menu2 {
  display: none;
}

@media (width <= 820px) {
  .paralax-image {
    height: 50vh !important;
  }

  .paralax-content-info-blue-tourism {
    padding: 20px 40px 20px 20px;
  }

  .paralax-header-icon-continer {
    top: -200px;
    left: -150px;
   }

   .first-form-white-title-header{
    font-size: 1.5rem;
    letter-spacing: 1px;
   }

  .paralax-header-text-continer {
    left: -221px;
    top: 10px;
  }

  .paralax-background-text-blue {
    border-radius: 10px;
    padding: 0.5rem 2rem;
    width: 18rem;
  }

  .paralax-content-info-blue-text-container {
    flex-direction: column;
  }

  .paralax-content-info-blue-text-container-inner-subtitle {
    font-size: 1.7rem;
  }

  .paralax-content-info-blue-text-container-inner-title {
    font-size: calc(1.3rem + 1.5vw);
  }

  .paralax-content-info-blue-text-container {
    top: -150px;
  }

  .paralax-video {
    height: 33vh;
  }

  .paralax-content-info-yellow {
    padding: 24px 24px 24px 20px;
    width: 70% !important;
  }

  .first-form-white-subtitle {
    margin-top: 0;
    font-size: calc(1.1rem + .6vw);
  }

  .paralax-icons-container {
    display: flex;
    flex-direction: column;
  }

  .paralax-card-group-item {
    width: 100%;
    margin-top: 1rem;
    gap: 10px;
    margin-bottom: 0.5rem;
  }

  .paralax-blue-text {
    width: 85%;
    font-size: 16px;
  }

  .paralax-google-reviews-container-inner {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  .paralax-content-info-city{
    padding: 50px 20px 20px;
  }

  .paralax-google-reviews-testimonies-container {
    display: none;
  }

  .paralax-content-info-yellow-gradient {
    padding: 40px 20px;
  }

  .paralax-menu-products-continer {
    gap: 0;
    flex-direction: column;
  }

  .paralax-menu-continer {
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 3px;
  }

  .paralax-menu-btn {
    width: 5rem;
    font-size: 0.7rem;
    padding: 5px;
    align-items: center;
    gap: 5px;
    justify-content: center;
  }

  .paralax-image-products-menu {
    display: none;
  }

  .paralax-image-products-menu2 {
    display: flex;
  }

  .paralax-white-cards-title {
    font-size: 20px;
  }

  .paralax-white-cards-continer {
    padding-top: 10px;
    margin-bottom: 3rem;
  }

  .paralax-white-cards-inner {
    padding: 10px;
    gap: 5px;
    font-size: 12px;
  }

  .paralax-white-cards-inner-title {
    font-size: 16px;
  }

  .paralax-white-cards-inner-buybutton {
    padding: 5px 12px;
  }

  .paralax-white-cards-inner-buybutton-text {
    font-size: 12px;
  }

  .paralax-white-cards {
    gap: 5px;
  }

  .paralax-content-info-blue {
    padding: 20px 40px 20px 20px;
  }

  .paralax-content-info-blue-work {
    padding: 20px 40px 20px 20px;
  }

  .paralax-content-info-blue-residence {
    padding: 20px 40px 20px 20px;
  }

  .paralax-content-info-blue-refugio {
    padding: 20px 40px 20px 20px;
  }
}
