.cotization-form{
  display: flex;
  flex-direction: column;
  width: 18rem;
  border-radius: 30px;
  padding: 10px;
  background-color: #E9F9FF;
}

.rejected-doc {
    background-color: #FFFBEB;
    width: 100%;
    height: 4.5rem;
    border-radius: 10px;
    border-left: 5px solid #FBBF24;
    display: flex;
    flex-direction: row;
    padding: 0 2rem;
    align-items: center;
    gap: 1rem;
  }

  .checkbox-red-continer {
    background-color: #FBBF24;
    padding: 5px;
    width: 35px;
    height: 35px;
    border-radius: 5px;
  }

  .checkbox-red {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background-color: #FBBF24;
    padding: 5px;
    font-size: 12px;
  }


.title-question{
    color: #0B76B7;
}

.container-questions{
    display: flex;
    gap:10px;
    margin-right: 8rem;
    width: 18rem;
    font-size: 0.7rem;
}

.questions-container{
    display: flex;
    flex-direction: row;
}

.container-all-questions{
  display: flex;
  flex-direction: row;
  background-color: #E9F9FF;
  border-bottom: 1px solid #E9F9FF;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.quantity-product{
    color: #1A8245;
    background-color: #DAF8E6;
    border-radius: 30px;
    padding: 3px;
    width: 6rem;
    font-size: 0.6rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-product-list{
    display: flex;
    gap:10px;
    width: fit-content;
    height: fit-content;
    border-bottom: 1px solid;
    padding-bottom: 1rem;
}

.container-name-description{
    display: flex;
    flex-direction: column;

}

.price-product{
    color: black;
    background-color: white;
    width: 9rem;
}

.name-product{
    color:black;
    display: flex;
    gap: 5px;
}

.container-price-quantity{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap:10px;


}

.name-description{
    font-size: 0.6rem !important;
}

.container-cotization-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 4rem;
    margin-top: 1rem;
    margin-bottom: 4rem;
}

.answers-container {
  background-color: #E9F9FF;
  max-height: 90%;
  width: 100%;
  border-radius: 10px;
  padding: 30px 20px;
}

.btn-aplicant-inf {
  border-radius: 6px;
  padding: 10px;
  color: #0B76B7;
  font-size: 12px;
  border: 1px solid #0B76B7;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.title-cotization{
    gap:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

}

.container-product-list{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-items: center;
    width: 100%;

}

.total-price{
    color: #1A8245;
    background-color: #DAF8E6;
    border-radius: 6px;
    padding: 10px;
    width: 15rem;
    display: flex;
    justify-content: center;

}

.container-total-price{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
}

.complete-product{
    display: flex;
    gap:10px;
    flex-direction: row;
    width: 100%;
justify-content: space-between;
}

@media (width <= 1151px) {
    .container-cotization-row{
        display: flex;
        flex-direction:column-reverse;
        justify-content: space-around;

    }

    .container-all-questions{
      display: flex;
      flex-wrap: wrap;
      background-color: #d0f0fd88;
    }

    .complete-product{
        display: flex;
        gap:10px;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .container-price-quantity{
        display: flex;
        flex-direction: column;
        justify-content: center;;
        gap:10px;
        align-items: center;

    }

    .phone-selector{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .text-importante{
        font-size: 1rem !important;
    }

    .container-name-description{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .name-description{
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
    }

    .btn-aplicant-inf{
        width: 100%;
    }

    .rejected-doc {
        padding: 0 1rem;
      }
  }
