@font-face {
  font-family: Lato;
  src: url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
  font-weight: 300, 400, 700;
}

.swal2-title{
  font-family: Lato, sans-serif;
}

.swal2-html-container{
  font-family: Lato, sans-serif;
}

.swal2-confirm{
	font-family: Lato, sans-serif;
  }

.swal2-popup .swal2-styled {
  font-family: Lato, sans-serif !important;
  font-size: 1.1rem;
  color: white !important;
  border-color: #3b00b1;
  border-radius: 45px !important;
  transition: all 0.2s ease-in-out
}


.swal2-validation-message {
	font-family: Lato, sans-serif;
}

.swal2-validation-message::before {
	background-color: #bf3c3c;
}

.swal2-icon-content {
  font-family: Lato, sans-serif;
}


.btn-with-border {
  border: 2px solid white;
  border-radius: 50px;
  padding: 10px 20px; /* Ajusta según sea necesario */
  background-color: #2CD673;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 50px;
  box-shadow: 0px 10px 15px 0px rgba(5, 13, 29, 0.18);
}
.btn-with-border-no-shadow:focus-visible {
  outline: none; /* Elimina el contorno predeterminado */
  box-shadow: none; /* Elimina la sombra predeterminada */
  /* Agrega cualquier otro estilo personalizado para el estado :focus-visible según tus necesidades */
}
.btn-with-border-no-shadow:focus {
  outline: none; /* Elimina el contorno predeterminado */
  box-shadow: none; /* Elimina la sombra predeterminada */
  /* Agrega cualquier otro estilo personalizado para el estado :focus-visible según tus necesidades */
}
.btn-with-icon {
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 20px; /* Ajusta según sea necesario */
  background-color: white;
  box-shadow: none !important; /* Elimina el box-shadow */
}

.btn-no-shadow{
  border: 2px solid white;
  border-radius: 50px;
  padding: 10px 20px;
  background-color: #333;
  box-shadow: none !important;
  color:white !important;
}

.btn-with-icon::after {

  margin-left: 10px; 
}

.swal2-icon.swal2-success .swal2-success-ring {
  border: 4px solid #13C296 !important;
}

.swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: #13C296 !important;
}



.swal2-icon.swal2-info {
  border-color:  #13C296 !important;
  color:   #13C296 !important;
}

@media screen and (max-width: 768px) {
  .responsive-div-questions{
display: flex;
flex-direction: column;
  }
}
