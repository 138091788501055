.editbutton {
  margin: 0 auto;
  margin-top: 13px !important;
  margin-bottom: 10px !important;
  width: 100px;
  background-color: white;
  border: 1px solid rgb(191 60 60);
  color: rgb(191 60 60) !important;
}

.deletetbutton {
  margin-top: 13px !important;
  margin-bottom: 10px !important;
  width: 100px;
  margin: 0 auto;
  background-color: rgb(191 60 60);
  border: 1px solid rgb(191 60 60);
  color: white !important;
}

.editbutton:hover {
  background-color: rgb(191 60 60);
  color: white !important;
}

.deletebutton:hover {
  background-color: white;
  color: rgb(191 60 60) !important;
}

.header-card {
  background-color: rgb(191 60 60);
  height: 4rem;
  display : flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
}

