body {
  font-family: 'Lato', sans-serif !important;
}



@media (max-width: 990px) {
  .button-exit{
    position: relative;
    left: 1%;
  }
  .step-two-container{
    display: inline-flex;
  }

}

@media (max-width: 768px) {
  .button-container{
    flex-direction: column;
  }
  .custom-input{
    width: 100%;
    padding: 10px;
  }
  .container-input-shopping{
    width: 90% !important;
    margin-bottom: 10px;
  }
  .buttonverify{
    margin-left: 0 !important;
    width: 50% !important;
  }

}

.stepper-container-employer {
  display: 'flex';
  justify-content: 'space-between';
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.step-two-container{
  margin-left: 20%;
  width: 60%;
  display: flex;
}

.step-two-container-cont{
  width: 100%;
  height: 110px;
  top: -65px;
  margin-top: 65px;
  padding-right: 40px;
  padding-bottom: 5px;
  background: white;
  position: fixed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}


.progress {
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #13C296;
  transition: width 1s ease-in-out;
}

.startbutton-disc3 {
  font-size: 1.1rem;
  color: white !important;
  background-color: #333;
  border: 1px solid white;
  padding: 16px 24px 16px 24px;
  width: 200px;
  height: 56px;
  border-radius: 45px;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
}

.backbutton-disc3 {
  font-size: 1.1rem;
  color: #333 !important;
  background-color: white;
  border: 1px solid #333;
  padding: 16px 24px 16px 24px;
  width: 200px;
  height: 56px;

  border-radius: 45px;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
}

.startbutton-disc3:hover {
  font-size: 1.1rem;
  color: white !important;
  background-color: #333;
  border: 1px solid white;
  padding: 16px 24px 16px 24px;
  width: 200px;
  height: 56px;
  border-radius: 45px;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
}

.backbutton-disc3:hover {
  font-size: 1.1rem;
  color: #3b00b1 !important;
  background-color: white;
  border: 1px solid #3b00b1;
  padding: 16px 24px 16px 24px;
  width: 200px;
  height: 56px;
  border-radius: 45px;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
}




.container_sc {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
}


.container-input-shopping {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}


.container-input-shopping2 {
  display: flex;
  flex-direction: column;
  margin-right: 24rem;
  width: 60%;
}

.docusign-input {
  background-color: #FFFBEB;
  padding: 12px 24px;
  border-radius: 10px;

}

.input_info {
  max-width: 700px;
  width: 90%;
  height: 46px;
  border-radius: 6px;
  border: 1px solid #DFE4EA;
  padding: 12px 16px 12px 20px;
}

.custom-input{
  max-width: 700px;
  width: 90%;
}

.input_info2 {
  max-width: 700px;
  width: 90%;
  height: 46px;
  border-radius: 6px;
  border: 1px solid #DFE4EA;
  padding: 12px 16px 12px 20px;
}

.details_card {
  max-width: 23rem;
  height: fit-content;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  width: 23rem;
}

.summary_card {
  background-color: #fff;
  height: fit-content;
  border-radius: 8px;
  padding: 10px;
  box-shadow: rgba(122, 122, 122, 0.25) 0 2px 5px -1px, rgba(92, 92, 92, 0.3) 0 1px 3px -1px;
}

.summary_card_combo {
  background-color: #fff;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out !important;
  border-radius: 8px;
  padding: 10px;
  box-shadow: rgba(122, 122, 122, 0.25) 0 2px 5px -1px, rgba(92, 92, 92, 0.3) 0 1px 3px -1px;
}


.summary_card_combo.visible {
  max-height: fit-content;
  /* Ajusta la altura máxima deseada */
  opacity: 1;
}

.summary_card_combo.hidden {
  max-height: 0;
  opacity: 0;
}

.card_item {

  display: flex;
  position: relative;
  margin: 10px 0;
  gap: 10px;
  flex-direction: column;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 5px;
}

.card_item .product_img {
  height: 80px;
  margin-right: 20px;
  width: 70px;
  border-radius: 5px;
}

.product_img {
  font-size: 20px;
  color: #1e212d;
  margin: 5px 0;
}

.product_info {
  color: #a1a1a1;
  font-size: 14px;
}

.product_rate_info {
  display: flex;
  margin: 5px 0;
  align-items: center;
  justify-content: space-between;
}


.pqt {
  font-size: 20px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}

.pqt-plus,
.pqt-minus {
  background: #fcfcfc;
  border: none;
  font-size: 20px;
  height: 100%;
  padding: 0 15px;
}

.pqt-plus:hover,
.pqt-minus:hover {
  background: #bf3c3c;
  color: #fff;
  cursor: pointer;
}

.pqt-plus {
  line-height: 30px;
}

.pqt-minus {
  line-height: 30px;
}

.order_price,
.order_service,
.order_total {
  display: flex;

  justify-content: space-between;
  padding: 10px;
}

.order_price,
.order_service {
  color: #a1a1a1;
}

.order_total {
  font-weight: 600;
}




.first_lastName,
.shipping_card,
.address {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.new_card {
  width: 100%;
  height: 100px;
  border: 2px solid #53b5aa;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.add_savedcard {
  width: 100%;
  height: 100px;
  border: 2px solid #a1a1a1;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.proced_payment {
  padding: 10px 20px;
  width: 200px;
  border: 2px solid #f5f5f5;
  background-color: #53b5aa;
  color: #000;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
}


.order_summary {
  width: 100%;
}

.summary_card {
  width: 100%;
}

.product_rate_info {
  width: 100%;
}

.pqt {
  margin-left: 15px;
  margin-right: 15px;
}

.nextButton {
  font-family: 'CocogoosePro', sans-serif !important;
  font-size: 1.1rem;
  color: white !important;
  background-color: #b10000;
  border-color: #b10000;
  padding: 20px;
  width: fit-content;
  height: 3rem;
  border-radius: 45px;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product_info {
  width: 100%;

}

.product_info_individual {
  width: 100%;


}

.group_info {
  padding: 2rem;
  width: 22rem;
}

.items_info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.container-questions-shopping {
  width: 100%;
}

.title_employer {
  font-size: 14px;
  font-weight: 700;
  color: #374151;
  margin-bottom: 10px;
  margin-top: 10px;
}

.payment_details {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* 
  botones */

.show-btn {
  transition: 0.2s;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 30%;
  padding: 2px 2px;
  border: 1px solid #bf3c3c;
}

.show-btn:hover {
  transition: 0.2s;
  width: 25px;
  height: 25px;
  border-radius: 30%;
  border: 1px solid #bf3c3c;
}

.show-btn-presed {
  border: 1px solid #bf3c3c;
  border-radius: 30%;
  animation-name: pressed;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.show-btn-presed:focus {
  outline: none
}

.show-btn-presed:focus-visible {
  outline: none
}

.show-btn-presed2 {
  border: 1px solid #bf3c3c;
  border-radius: 30%;
  animation-name: pressed2;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.show-btn-presed2:focus {
  outline: none
}

.show-btn-presed2:focus-visible {
  outline: none
}

.button-hover-asign {
  padding: 12px 36px 12px 36px;
  letter-spacing: 2.5px;
  color: #01A9DB;
  background-color: #fff;
  border: 1px solid #01A9DB;
  border-radius: 45px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  display: flex;
  font-weight: bold;
  height: fit-content !important;
  position: relative;
  bottom: 3rem;
}

.button-hover-asign:hover {
  background-color: white;
  border: 1px solid #01A9DB !important;
  box-shadow: 0px 20px 20px 0px #050D1D33;
  color: #01A9DB;
  transform: translateY(-4px);
  border: 1px solid rgb(255, 255, 255);
  font-weight: bold;
  display: flex;
}

.input-employer {
  width: 360px !important;
  height: 46px;
  border-radius: 6px;
  border: 1px solid #8899A8;
  padding: 12px 16px 12px 16px;
}

.input-employer2 {
  width: 260px !important;
  height: 46px;
  border-radius: 6px;
  border: 1px solid #8899A8;
  padding: 12px 16px 12px 16px;
}

.list-hover:hover{
  background-color: #f4f4f4 !important;
}

.quita-hover:hover{
  border-bottom: 1px solid #E10E0E;
}

.custom-toast-error {
  background-color: #E6F4EA !important;
  color: #740202 !important;
  border-radius: 8px !important;
  padding: 16px 20px !important;
  display: flex !important;
  align-items: center !important;
}


.custom-toast {
  background-color: #E6F4EA !important;
  color: #1A8245 !important;
  border-radius: 8px !important;
  padding: 16px 20px !important;
  display: flex !important;
  align-items: center !important;
}

.custom-toast .Toastify__toast-body {
  display: flex;
  align-items: center;
}

.custom-toast .Toastify__toast-icon {
  margin-right: 10px;
}

.custom-toast .Toastify__close-button {
  display: none !important;
}

/**/

.services-container{
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-left: 2rem
}

.service-block{
  display: flex;
  border-color:#a1a1a1;
  border-radius: 10%;
  flex-direction: column;
  width: 15%;
  height: width;
  padding: 12px; /* Space inside the border */
  box-sizing: border-box; /* Ensures padding doesn't affect width */
}

.add-service{
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%
}

.disclaimer-container-factura{
  display: flex;
  flex-direction: column;
  width: 90%;
  word-wrap: break-word;
  text-align: justify;
  background-color: #D6EAF8; /* Light blue background */
  padding: 15px; /* Space between text and background */
  border-radius: 5px; /* Slight rounded corners */
  font-style: italic; /* Italic text */
}

.workers-section{
    display: flex;
    justify-content: flex-end;
    width: 97%;
    margin-top: 1rem;
    margin-bottom: 1rem
}
.workers-container{
  background-color: #F5F3FF;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  padding: 10px 10px 10px 10px;
}

.title-worker{
  color: #5B21B6;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}


.container-text-1 {
  display: flex;
  width: 100%;
}

.main-link {
  width: 100%;
  margin-right: 2rem;
}

.text {
  color: black;
  display: flex;
  width: 20rem;
}

.button-container {
  display: flex;
  color: #01a9db;
  width: 50%rem;
  margin-right: -2rem;
  gap: px;
}

.action-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  width: 14rem;
  gap: 10px;
  border-bottom: 1px solid #01a9db;
  margin-bottom: 10px;
}

.button-text {
  color: #01a9db;
}

.services-selected-list-container > div {
  height: 100% !important;
}

@media (max-width: 768px) {
  .services-container{
    flex-direction: column;
    gap: 10px;
  }
  .workers-section{
    justify-content: flex-start;
    margin-top: 1rem
  }
  .stepper-container-employer {
    left: 35px;
  }
}

@media (max-width: 768px) {
  .container-text-1 {
    flex-direction: column;
    gap: 0.5rem;
  }

  .button-container {
    justify-content: flex-start;
    margin-top: 5px;
  }

  .action-button {
    width: fit-content;
    margin-top: 10px;
  }
  .service-block{
    flex-direction: row;
    width: 100%;
    height: width;
  }
}

/* inicio mobile employers*/

@media only screen and (max-width: 768px) {


  /*inicio paso 1*/
  .container-input-shopping,
  .container-input-shopping > h3  {
      margin-top: 0 !important;
      width: 100%;
  }
  
  
  
  /*cierre paso 1*/
  
  /*inicio paso 2*/
  .service-block {
          flex-direction: row;
          height: width;
          width: 100%;
          border-bottom: dotted 1px #dadada; /*nuevo*/
          padding: 12px 0; /*nuevo*/
          border-radius: 0; /*nuevo*/
          display: flex;
          justify-content: space-between !important;
          gap: 12px;
      }
   
   .service-block strong {
      flex: 2;
   }   
  
  
  
  .add-service {
      align-items: center;
      height: 100%;
      justify-content: flex-end;
      margin-top: 0px; /*nuevo*/
      padding: 0;
      flex: 1;
  
  }
  
  .add-service a {
      margin-bottom: 0 !important;
          padding: 4px 6px;
      
  }
  
  
  /*contenedor de form, actualmente la clase es mb-5 pl-5 pr-5*/
  .big-container > div > div {
      font-family: Lato;
      display: flex;
      flex-direction: column;
      padding: 0 24px !important;
      width: 100%;
      height: fit-content !important;
      margin-top: -120px;
      gap: 30px !important;
  }
  
  .services-container {
      flex-direction: column;
      gap: 0px;
      margin: 0;
      padding: 12px 24px;
      border: solid 1px darkgrey;
      border-radius: 10px;
  }
  
  .workers-section {
          justify-content: center;
          width: 100%;
      }
  
  .workers-container {
      width: 100%;
      padding: 16px 16px 24px 16px;
      border-radius: 4px;
      align-items: center;
  }
  
  .workers-container .main-link {
      margin: 0;
  }
  
  .workers-container .title-worker {
  /*    font-size: 12px;*/
  }
  
  .workers-container .text {
      color: #47188f;
      display: flex;
      width: 100%;
      font-size: 13px;
      font-weight: normal;
      justify-content: center;
  }
  
  .workers-container .container-text-1 {
              gap: 12px;
          margin-bottom: 20px;
          align-items: center;
      
  }
  
  .workers-container .button-container {
          padding: 0;
          margin: 0;
  }
  
  .workers-container .button-container .action-button  {
      align-items: center;
      margin: 0;
      padding: 6px 0;
  }
  
  .workers-container .button-container .action-button  a {
      font-size: 12px;
      font-weight: bold;
  
  }
  
  
  .service-block a {
      display: none;
  
  }
  
  
  /*div sin nombre, que contiene todas las divs, para definir orden en mobile*/
  .big-container > div > div {
  margin-top: 150px;
  width: 100%; 
  display: flex;  
  flex-direction: column; 
  }
  
  .services-container {order: 1;}
  
  .workers-section { order: 3; }
  
  /*div padre de servicios añadidos*/
  .services-selected-container {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      order: 2;
  }
  
  /* div contenedora de buttons */
  .buttons-container{
          display: flex;
          gap: 5px;
          margin-top: 0 !important;
          order: 5;
          padding-bottom: 6rem;
          flex-direction: row;
          align-items: stretch;
          width: 100%;
          justify-content: space-between !important;
  }
  
  .buttons-container button {
      font-size: 16px !important;
      width: auto !important;
      height: fit-content !important;
  }
  
  /* div contenedora de valor (padre de .div-price)*/
  .prices-container {
      order: 4;
  }
  
  .prices-container .div-price {
      flex-direction: column;
      margin: 24px 0 !important;
  }
  
  .prices-container .div-price > p {
      font-size: 16px;
  }
  
  .prices-container .div-price .container-price {
          align-items: center;
          flex-direction: row;
          gap: 12px;
          height: fit-content !important;
          padding: 16px !important;
  }
  
  .prices-container .div-price .container-price > div {
      flex-direction: row;
      gap: 6px;
  }
  
  .prices-container .div-price .container-price > div > div {
      margin: 0;
  }
  
  .prices-container .div-price .container-price p {
      font-size: 16px !important;
  }
  
  .prices-container .div-price .container-price .arrow-price {
  height: 24px !important;
          margin: 0 !important;
          padding: 0 !important;
          width: 24px !important;
  }
  
  
  
  /*div padre de servicios añadidos*/
  .services-selected-container > div {
      background-color: rgba(208, 240, 253, 0.4);
      display: flex;
      flex-direction: row;
      padding: 30px 16px !important;
      justify-content: flex-start;
      width: 100% !important;
  
  }
  
  .services-selected-container > div > div  {
      display: flex;
      place-content: center;
      align-items: center;
      width: 100%;
      justify-content: flex-start;
  }
  
  
  .services-selected-list-container > div {
      border-bottom: solid 1px rgb(11, 118, 183);
      display: flex;
      flex-direction: column;
      height: fit-content !important;
      width: 100%;
      flex-wrap: wrap;
      margin: 0 !important;
      padding: 24px 0;
      margin: 0 !important;
  }
  
  .services-selected-list-container > div:first-child {
      padding-top: 0;
  }
  
  .services-selected-list-container .services-table-container {
      height: fit-content !important;
  }
  
  .services-selected-list-container .services-table-container > div {
     height: fit-content !important;
      display: flex !important;
      width: 100%;
      margin: 12px 0;
      align-content: flex-start;
  /*    flex-direction: column !important;*/
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
  }
  
  .disclaimer-text-lmia-text-container {
      font-size: 13px !important;
  }
  
  
  
  .parent-services-container {
      
      display: flex;
      flex-direction: column;
      gap: 30px;
  }
  
  
  button.button-hover-asign {
      bottom: 0;
      width: min-content !important;
  }
  
  button.quita-hover {
      flex-direction: row !important;
  }
  .quita-hover img {
      width: 24px !important;
  }
  
  .big-container > div > div.full-container > div > div.workers-section > div.slide-over.opacity-100 > div.fixed.inset-0.overflow-hidden.flex.items-start.justify-end.z-10 > div > div > div > div:nth-child(4) * {
      flex-direction: column;
      max-width: 100% !important;
      gap: 12px !important;
      height: fit-content !important;
  }
  
  
  /* cierrre paso 2*/
  
  /*incio paso 3 */
  
  #element-to-capture > div:nth-child(2) {
      flex-direction: column !important;
      gap: 12px !important;
  }
  .employer-data-container {
      /* max-width: 100%; */
      width: 100%;
  /*    order: 2;*/
  }
  .total-price-container {
  /*    order: 1;*/
      margin: 0 !important;
  }
  
  .payment-method-selector {
      width: 100% !important;
  }
  
  .payment-method-selector div {
  width: 100% !important;
  }
  
  .paymenth-method-details-container {
      align-items: center !important;
  }
  
  .last-buttons-container {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: stretch;
      gap: 24px !important;
  }
  
  .last-buttons-container button {
      width: 100% !important;
      height: fit-content !important;
  }
  
  .quantity-product {
      height: fit-content !important;
      padding: 6px;
      border-radius: 3px;
      text-align: center;
  }
  
  .product-included-container {
      gap: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: stretch;
      flex-wrap: nowrap;
      justify-content: flex-start;
       align-items: stretch !important;
  
  }
  
  .price-producr-included img {
      width: 24px;
  }
  
  
  .price-producr-included {
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;
  }
  
  /*cierre paso 3*/
  
  
  
  }
  
  /* cierre mobile employers*/
  
  
  
  
  /*Employers inicio correcciones desktop */
  
  /*inicio paso 1*/
  
  .container-input-shopping {
      width: 100%;
  }
  
  .container-questions-shopping > div:nth-child(2) > div,
  .container-questions-shopping > div:nth-child(2) > div:nth-child(2) {
      max-width: 700px;
      margin: 30px auto;
      gap: 12px !important;
  }
  
  .container-questions-shopping .container-input-shopping,
  .container-questions-shopping .container-input-shopping input {
      width: 100% !important;
  }
  
  /*botonera*/
  .container-questions-shopping > div:nth-child(2) > div:nth-child(2) {
      justify-content: space-between !important;
      flex-direction: row;
      width: 100%;
  }
  
  
  /*cierre paso 1*/
  
  
  /*inicio paso 2*/
  .full-container {
      padding: 0 !important;
      max-width: 1100px !important;
      margin: 0 auto !important;
  }
  
  .parent-services-container {
      font-family: Lato;
      margin-top: 150px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      max-width: 1100px !important;
      align-content: flex-start;
      justify-content: center;
  }
  
  .workers-section,
  .service-selected-parent,
  .workers-container .title-worker {
      width: 100% !important;
  }
  
  .workers-container {
      width: fit-content !important;
      align-items: flex-end ;
      padding: 16px 16px 24px 16px !important;
  }
  
  .trabajadores-integrados {
      height: fit-content !important;
      padding: 4px 12px !important;
  }
  
  .buttons-container {
      justify-content: space-between;
      max-width: 700px;
      margin: 0 auto !important;
      width: 100%;
      justify-content: space-between !important;
  }
  
  .prices-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
  }
  
  .prices-container .div-price {
      margin-bottom: 0 !important;
  }
  
  .prices-container .container-price {
      height: fit-content !important;
      padding: 16px !important;
      width: fit-content;
  
  }
  
  /*cierre paso 2*/
  
  
  /*inicio paso 3*/
  #element-to-capture > div:nth-child(2) {
      font-family: Lato;
      margin-top: 150px;
      display: flex !important;
      gap: 48px !important;
      flex-direction: row ;
      max-width: 1100px !important;
      align-content: flex-start;
      justify-content: center;
      margin: 150px auto !important;
  }
  
  .employer-data-container {
          flex: 1;
  }
  .total-price-container {
      flex: 2;
  }
  
  
  
  .employer-data {
      width: 100% !important;
      height: auto !important;
  }
  
  
  
  /*cierre paso 3*/