
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


body {
    padding: 0;
    margin: 0;
    font-family: Montserrat, sans-serif;
}

.pre-evaluation-disclaimer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pre-evaluation-disclaimer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  gap: 1rem;
}

.pre-evaluation-disclaimer-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pre-evaluation-disclaimer-botons {
  display: flex;
  flex-wrap: wrap;
}

.pre-evaluation-container-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  height: 100%;
}

.pre-evaluation-container {
  font-family: Montserrat, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.range-slider {
  font-family: Montserrat, sans-serif;
  width: 300px;
  position: relative;
  margin-bottom: 20px;
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  outline: none;
  z-index: 1;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #007bff00;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  background: transparent;
}

.options {
  display: flex;
  gap:15px;
}

.option {
  margin: 0 10px;
  cursor: pointer;
}

.selector-landind-container-txt {
  height: 2rem;
  margin: 0;
  font-size: 1.5rem;
  margin-left: 1rem;
}

.selector-landing-container-btn {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  width: '30%';
}

.selector-landing-container-btn-inner {
  display: flex;
  flex-wrap: wrap;
  gap:1rem;
  align-items: center;
}


.option.selected {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  color: #007bff;
}

.slider-thumb {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-radius: 50%;
bottom: 11px;
  transform: translateY(-50%);
  z-index: 3;
  pointer-events: none;
}


.first-form-main-container{
  height: 100%;
  width: 100%;
  background-image: url('./assets/bg-canada.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.first-form-container {
  width: 100%;
  padding-right: 57px;
  padding-left: 57px;
  padding-bottom: 57px;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
}

.first-form-container-inner {
  width: 100%;
  height: fit-content;
  padding: 2rem;
}

.first-form-white-title {
  font-family: Montserrat, sans-serif !important;

  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
  color: #fff;
}

.first-form-white-subtitle {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.3rem + .6vw);
  margin-top: 0;
}

.first-form-white-text {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5rem;
  margin: 0;
}

.first-form-white-text-big {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: bold;
  line-height: 1.2;
  font-size: 2rem;
  margin: 0;
}


.first-form-yellow-title {
  font-family: Montserrat, sans-serif;
  color: #FFC64B;
  font-size: 3.75rem;
  font-weight: 800;
  letter-spacing: 3px;
}

.first-form-carrusel-container {
  background-color: white;
  padding: 28px 48px;
  border-radius: 20px;
  min-height: 250px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.first-form-carrusel-page {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  animation-duration: 0.5s;
  animation-fill-mode: forwards
}



.boton-blue-first-form {
  color: #FFF;
  background: #5D5FEF;
  box-shadow: 0 10px 14px 0  #5d5fef80;
  border: 2px solid #5D5FEF;
  display: flex;
  padding: 7px 24px 7px 12px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 10px;
  font-size: 0.7rem;
  font-weight: bold;
  gap: 6px;
  width: 10rem;
}

.boton-white-first-form {
  font-family: Montserrat, sans-serif;
  width: 10rem;
  display: flex;
  padding: 7px 24px 7px 12px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 10px !important;
  border: 2px solid #374151;
  background: #FFF;
  color: #374151;
  font-size: 0.7rem;
  font-weight: bold;
  gap: 6px;
}

.footer-first-form {
  background: #5d5fefcc;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  margin-bottom: 10rem;
}

.footer-first-form-image {
  padding: 45px;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.first-form-avatar {
  height: 75px;
  margin-left: -50px;
  width: 75px;
  background-color: white;
  border-radius: 50%;
}

.first-test-arrow-button {
  width: 48px;
  height: 48px;
  padding: 5px;
  border-radius: 50%;
  border: 4px solid #5D5FEF;
  margin-bottom: 10px;
}

.first-test-arrow-button-disabled{
  width: 48px;
  height: 48px;
  padding: 5px;
  border-radius: 50%;
  border: 4px solid #8899A8;
  margin-bottom: 10px;
}

.selector-pre-evaluation{
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-select-pre-evaluation {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");

  font-family: Montserrat, sans-serif;
  display: block;
  width: 100%;
  min-width: 18rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #dee2e6;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.boton-white-first-form:hover {
  border: 1px solid #5D5FEF;
  color: #5D5FEF;
}

.button-form-notify {
  font-family: Montserrat, sans-serif;
  display: flex;
  min-width: 12rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 30px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  color: #000;
  background-color: #2CD673;
  border-radius: 45px;
  box-shadow: 0px 10px 15px 0px #050D1D2E;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  border: 1px solid white;
  }

.button-form-notify:hover {
  font-family: Montserrat, sans-serif;
  padding: 16px 30px;
  display: flex;
  font-weight: 700;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #2CD673;
  box-shadow: 0px 20px 20px 0px #050D1D33;
  color: #fff;
  transform: translateY(-7px);
  border: 1px solid white;
}

.button-form-notify-disable{
  font-family: Montserrat, sans-serif;
  display: flex;
  min-width: 12rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 30px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  color: #000;
  background-color: #8899A8;
  border-radius: 45px;
  box-shadow: 0px 10px 15px 0px #050D1D2E;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  border: 1px solid white;
}

.text-question-container{
  display: flex;
  flex-direction: row;
  align-items:center;
  width:100%;
  justify-content:center;
  gap:40px
}

.btn-question-container-carrusell {
  display: flex;
  flex-direction: row;
  gap:1rem;
  align-items: center;
}

.text-question-container-input{
  display: flex;
  align-items:center;
  width:100%;
  justify-content:center;
}

.input-pre-evaluation {
  font-family: Montserrat, sans-serif;
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  max-width: 15rem;
  margin-bottom: 8px;
}

.input-pre-evaluation-email{
  font-family: Montserrat, sans-serif;
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  max-width: 15rem;
  margin-bottom: 8px;
}

.input-pre-evaluation:focus {
  outline: none;
  border-color: #007bff;
}

.input-container-pre-evaluation{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  justify-content: center;
  align-items: center;
}


@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

/* Animación de salida */
@keyframes slide-out-to-left {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}


.slide-in {
  animation-name: slideInFromRight;
}

.slide-out {
  animation-name: slide-out-to-left;
}



.reviews-container {
  font-family: Arial, sans-serif;
}

.reviews-list {
  list-style-type: none;
  padding: 0;
}

.review-card {
  height: 17rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.review-header {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f9f9f9;
}

.review-author-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

.review-author-info {
  flex-grow: 1;
}

.review-author-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.review-rating {
  margin: 0;
  color: #000; /* Cambiado a negro */
  display: flex;
  align-items: center;
}

.star {
  width: 16px;
  height: 16px;
  fill: #FFD700; /* Color de las estrellas */
  margin-right: 5px;
}

.review-content {
  padding: 15px;
}

.review-text {
  margin: 0;
}

.title-condition {
  margin: 0;
  font-size: 1.5rem;
  margin-left: 1rem;
}

@media (width <= 820px) {
  .pre-evaluation-disclaimer {
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: #374151c4;;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 9999;
  }

  .pre-evaluation-disclaimer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    gap: 0.5rem;
  }

  .text-question-container{
    flex-direction: column;
    gap:20px
  }

  .first-form-carrusel-page{
    flex-direction: column;
  }

  .btn-question-container-carrusell {
    display: flex;
    flex-direction: column;
  }

  .first-form-container {
    width: 100%;
    height: 100%;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 25px;
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
  }

  .first-form-container-inner {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .first-form-white-title{
    font-size: 1.8rem;
  }

  .first-form-yellow-title {
   font-size: 1.8rem;
   letter-spacing: 1px;
  }

  .first-form-white-subtitle{
    margin-top: 1.2rem;
  }

  .first-test-arrow-button {
    margin-top: 1rem;
  }

  .first-test-arrow-button-disabled{
    margin-top: 1rem;
  }

  .first-form-carrusel-container {
    padding: 20px;
    margin-top: 20px;
  }

  .footer-first-form {
    flex-direction: column;
  }

  .footer-first-form-image {
    padding: 20px 45px;
    padding-left: 80px;

  }

  .footer-btn-text{
    padding:0 40px;
    padding-bottom: 60px;
  }

  .first-form-white-text {
    font-size: 1rem;
  }

  .first-form-white-text-big {
    font-size: 1.3rem;
  }

  .range-slider {
    width: 250px;
  }

  .options {
    gap:0;
  }

  .selector-landing-container-btn {
    width: 100%;
    justify-content: center;
  }

  .selector-landing-container-btn-inner {
    justify-content: center;
  }

  .first-form-main-container{
    height: 110%;
  }

  .text-question-container-input{
    flex-direction: column;
  }

  .input-container-pre-evaluation{
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .title-condition {
    font-size: 1.4rem;
    margin-left: 0;
  }
}
