:root {
  --red: hsl(0deg 78% 62%);
  --cyan: hsl(180deg 62% 55%);
  --orange: hsl(34deg 97% 64%);
  --blue: hsl(212deg 86% 64%);
  --varydarkblue: hsl(234deg 12% 34%);
  --grayishBlue: hsl(229deg 6% 66%);
  --verylightgray: hsl(0deg 0% 98%);
  --weight1: 200;
  --weight2: 400;
  --weight3: 600;
}

@media only screen and (width >= 700px) {
  .addtext {
    padding: 25px;
  }
  
  .parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
  }

  .card {
    width: 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #bf3c3c;
    box-shadow: rgb(0 0 0 / 10%) 0 1px 4px;
    margin: 1rem;
  }
}

@media only screen and (width >= 1000px) {
  .addtext {
    padding: 25px;
  }

  .parent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
  }

  .card {
    width: 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #bf3c3c;
    box-shadow: rgb(0 0 0 / 10%) 0 1px 4px;
    margin: 1rem;
  }

  .card .close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    margin: 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #bf3c3c;
    border: 1px solid #bf3c3c;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .card .banner {
    background-image: url('https://images.unsplash.com/photo-1545703549-7bdb1d01b734?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 11rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
  }

  .card .banner svg {
    background-color: #fff;
    width: 8rem;
    height: 8rem;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 30%);
    border-radius: 50%;
    transform: translateY(50%);
    transition: transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }

  .card .banner svg:hover {
    transform: translateY(50%) scale(1.3);
  }

  .card .menu {
    width: 100%;
    height: 5.5rem;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    box-sizing: border-box;
  }

  .card .menu .opener {
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
    border-radius: 50%;
    transition: background-color 100ms ease-in-out;
  }

  .card .menu .opener:hover {
    background-color: #f2f2f2;
  }

  .card .menu .opener span {
    background-color: #404040;
    width: 0.4rem;
    height: 0.4rem;
    position: absolute;
    top: 0;
    left: calc(50% - 0.2rem);
    border-radius: 50%;
  }

  .card .menu .opener span:nth-child(1) {
    top: 0.45rem;
  }

  .card .menu .opener span:nth-child(2) {
    top: 1.05rem;
  }

  .card .menu .opener span:nth-child(3) {
    top: 1.65rem;
  }

  .card h2.name {
    padding: 0 2rem 1.5rem;
    padding-top: 1.5rem;
    margin: 0;
    font-weight: 600;
    background-color: #bf3c3c;
    color: #fff;
    justify-content: left;
    display: flex;
  }

  .card .title {
    color: #7c7c7a;
    font-size: 0.85rem;
    text-align: left;
    padding: 0 2rem 1.2rem;
  }

  .card .actions {
    padding: 0 2rem 1.2rem;
    display: flex;
    flex-direction: column;
    order: 99;
  }

  .card .actions .follow-info {
    padding: 0 0 1rem;
    display: flex;
    font-size: 0.75rem;
  }

  .card .actions .follow-info h2 {
    text-align: center;
    width: 50%;
    margin: 0;
    box-sizing: border-box;
  }

  .card .actions .follow-info h2 a {
    text-decoration: none;
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.8rem;
    transition: background-color 100ms ease-in-out;
  }

  .card .actions .follow-info h2 a span {
    color: #1c9eff;
    font-weight: bold;
    transform-origin: bottom;
    transform: scaleY(1.3);
    transition: color 100ms ease-in-out;
  }

  .card .actions .follow-info h2 a small {
    color: #afafaf;
    font-size: 0.85rem;
    font-weight: normal;
  }

  .card .actions .follow-info h2 a:hover {
    background-color: #f2f2f2;
  }

  .card .actions .follow-info h2 a:hover span {
    color: #007ad6;
  }

  .card .actions .follow-btn button {
    color: inherit;
    font: inherit;
    font-weight: bold;
    background-color: #ffd01a;
    width: 100%;
    border: none;
    padding: 1rem;
    outline: none;
    box-sizing: border-box;
    border-radius: 1.5rem/50%;
    transition: background-color 100ms ease-in-out,
      transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }

  .card .actions .follow-btn button:hover {
    background-color: #efb10a;
    transform: scale(1.1);
  }

  .card .actions .follow-btn button:active {
    background-color: #e8a200;
    transform: scale(1);
  }

  .card .desc {
    text-align: justify;
    padding: 0 2rem 2.5rem;
    order: 100;
  }
}

@media only screen and (device-width >= 0) and (device-width <= 480px) {
  .card {
    background-color: #fff;
    max-width: 300px;
    display: block;
    flex-direction: column;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 16%) 0 1px 4px;
    margin: 1rem;
  }

  .addtext {
    padding: 15px;
  }

  .blockstyle {
    margin: 0 auto;
  }
}

.ant-checkbox-wrapper{
  width: 30px !important;
  height: 30px !important;
}

.ant-checkbox-inner {
  width: 30px !important;
  height:30px  !important;
  border-radius: 10px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(11 118 183) !important;
  border-color: rgb(11 118 183) !important;
  width: 30px !important;
  height: 30px !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after{
  margin-left: 5px;
}

.ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: rgb(11 118 183) !important;
  border-color: rgb(11 118 183) !important;
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: rgb(11 118 183)!important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: rgb(11 118 183)!important;
}


