


.customConfirmButton {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #7066e0;
    color: #fff;
    font-size: 1em;
  }

  @media (width <= 653px) {
    .customConfirmButton {
      border: 0 !important;
      border-radius: 0.25em !important;
      background: initial !important;
      background-color: #bf3c3c !important;
      color: #fff !important;
      margin-top: -2rem !important;
      font-size: 1em !important;
    }

    .input-container{
      display: flex;
      flex-direction: column;
      align-items: center;
     
    }

    .text-expired{
      width: 16rem !important;
    }
  }


