/* Center the animation vertically and horizontally */
.center-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
  }
  
  /* Set responsive sizes for the Lottie animation */
  .lottie-container {
    width: 700px;
    height: 700px;
  }

  .lottie-container2 {
    width: 100% !important;
    height: 500px;
    display: flex;
    flex-direction: column; /* Default horizontal layout */
  }
  
  /* Responsiveness for tablets */
  @media (max-width: 768px) {
    .lottie-container {
      width: 200px;
      height: 200px;
    }

    .lottie-container2 {
      flex-direction: column; /* Change to vertical layout */
      height: auto; /* Let the height adjust automatically */
    }

    .icon-social-media{
      width: 23px !important;
      height: 23px !important;
  }
    
  }
  
  /* Responsiveness for mobile devices */
  @media (max-width: 480px) {
    .lottie-container {
      width: 150px;
      height: 150px;
    }

    

    .lottie-container2 {
      flex-direction: column; /* Change to vertical layout */
      height: auto; /* Let the height adjust automatically */
    }
  }
  


  .language-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
  }
  
  .language-title {
    text-align: center;
    margin-bottom: 30px; /* Incrementa el margen para mejorar la separación */
    font-size: 1.5rem; /* Aumenta el tamaño de la fuente para mejorar la legibilidad */
  }
  
  .language-buttons {
    display: flex;
    gap: 30px;
    justify-content: center;
    flex-wrap: wrap; /* Permite que las banderas se muevan a una nueva línea si no caben */
  }
  
  .language-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .language-button:hover {
    transform: scale(1.1);
  }
  
  .flag-icon {
    width: 100px;
    height: 65px; /* Establece un tamaño fijo para que las banderas sean uniformes */
    margin-bottom: 5px;
    object-fit: cover; /* Ajusta la imagen dentro del contenedor sin deformarla */
  }

  .flag-label {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px; 
}

.nav-bar1{
  background-image: linear-gradient(90deg, #3b00b1, #007080 25%, #c50043 50%, #ffa500 75%, #fa5d57);
}

.icon-social-media{
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    padding: 5px 0;
    transition: all .2s;
    display: flex;
    margin-top: -18px;
}

.icon-social-media :hover{
  transform: scale(0.9);
  border-radius: 50%;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.9);
}

.social-link:hover {
  background-color: #0000;
  transform: scale(.95);
}

.nav-top-social-2 {
  margin-top: 18px;
    clear: none;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-direction: row;
    flex: 0 auto;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: center;
    align-self: auto;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    position: absolute;
    inset: 2% 3% auto auto;
}


.text-block-25 {
  width: 100%;
  clear: none;
  color: #f0edee;
  text-align: center;
  white-space: normal;
  object-fit: fill;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  padding-left: 0;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  display: block;
  position: static;
  overflow: visible;
}
  
  .center-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  /* Media Queries para pantallas pequeñas */
  @media (max-width: 768px) {
    .language-buttons {
      flex-direction: column;
      gap: 15px;
    }
    .frame{
      margin-left: 1%;
    }
  
    .flag-icon {
      width: 80px; /* Reduce el tamaño de las banderas para pantallas más pequeñas */
      height: 50px;
    }
  
    .language-title {
      font-size: 1.2rem; /* Ajustar el tamaño del título para pantallas más pequeñas */
    }
    .nav-top-social-2{
      margin-top: 18px;
    }
    .text-block-25{
      margin-left: 2px;
      text-align: left !important;
    }
  }
  
  @media (max-width: 480px) {
    .language-selector-container {
      padding: 10px; /* Ajusta el padding para pantallas muy pequeñas */
    }
    .text-block-25{
      margin-left: 2px;
      font-size: 1rem;
      text-align: left !important;
    }
  
    .language-title {
      font-size: 1rem; /* Reducir más el tamaño del título si es necesario */
      margin-bottom: 20px;
    }
  
    .flag-icon {
      width: 60px; /* Tamaño más pequeño para pantallas muy pequeñas */
      height: 40px;
    }
  }

  #employer-section-container {
    display: flex;
    justify-content: flex-end;
    width: 97%;
    margin-top: 1rem;
    padding: 10px; /* Padding lateral del contenedor */
  }
  
  #employer-section {
    background-color: #f5f3ff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%; /* Usa porcentaje para un diseño más responsive */
    padding: 1rem;
  }
  
  .employer-heading {
    color: #5b21b6;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
  
  .employer-content {
    display: flex;
    flex-direction: row; /* Predeterminado a dos columnas */
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 0/7rem; /* Espacio entre el texto y el botón */
  }
  
  .employer-description {
    flex: 1; /* Toma el espacio disponible */
    font-size: 0.9rem;
    margin-right: 1rem;
  }
  
  .employer-description strong {
    color: black;
  }
  
  .employer-link-container {
    display: flex;
    justify-content: flex-start;
    color: #01a9db;
    width: fit-content;
    gap: 5px;
  }
  
  .employer-link {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #01a9db;
    color: rgb(1, 169, 219);
  }
  
  /* Media Query para pantallas menores a 990px */
  @media (max-width: 990px) {
    #employer-section {
      width: 100%; /* Ancho completo en pantallas más pequeñas */
      padding: 1rem; /* Mantener padding lateral en pantallas pequeñas */
    }
  
    .employer-content {
      flex-direction: column; /* Elementos apilados verticalmente */
      align-items: flex-start;
      gap: 0.02rem; /* Mantén el espacio entre elementos también en versión apilada */
    }
  
    .employer-link-container {
      margin-top: 1rem; /* Espacio entre la descripción y el enlace en pantallas pequeñas */
    }
  }
  