.p-fileupload .p-fileupload-buttonbar .p-button {
  border-color: #4c1a1a !important ;
    background-color: #bf3c3c !important;
}


button:not(button, a, .p-disabled):active {
    background-color: #7e2929 !important ;
    border-color: #4c1a1a !important;
}


.p-button:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #bf3c3c, 0 1px 2px 0 black !important;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    padding: 0.5rem !important;
    gap: 1px  !important;

}